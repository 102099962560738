import React, { useState } from 'react';
import { PiArrowRightThin, PiArrowLeftThin } from "react-icons/pi";

import '../../styles/collectionsPage.css';
import '../../styles/homePage.css';
import '../../styles/photoPage.css';



import PhotoDescription from '../photodisplay/PhotoDescription';
import PhotoDescriptionPhone from '../photodisplay/PhotoDescriptionPhone';
import PhotoGrid from '../photodisplay/PhotoGrid';
import {Link} from 'react-router-dom';
import { PiArrowUUpLeftThin } from "react-icons/pi";
import PhotoFrame from './PhotoFrame';

export default function PhotoDisplayV2({description, items}) {

    const [itemIndex, setItemIndex] = useState(0);
    const [nextColor, setNextColor] = useState("rgba(232, 188, 130, 1)");
    const [backColor, setBackColor] = useState("rgba(232, 188, 130, 1)");
    const [isGalleryMode, setIsGalleryMode] = useState(false);


    const nextStyles = {
        "-webkit-transition": "0.25s ease-in-out",
        'color': nextColor,
        'text-align': "left",
        'width' : '3vw'
    }

    const backStyles = {
        "-webkit-transition": "0.25s ease-in-out",
        'color': backColor,
        'text-align': "right",
        'width' : '3vw'
        }

    const nextPhoneStyles = {
        "-webkit-transition": "0.25s ease-in-out",
        'color': nextColor,
        'text-align': "left",
    }

    const backPhoneStyles = {
        "-webkit-transition": "0.25s ease-in-out",
        'color': backColor,
        'text-align': "right",
        }

    const changePhoto = async (isNext) => {
        await new Promise(r => setTimeout(r, 200));
        if (isNext) {
            setItemIndex((itemIndex + 1) % items.length);
        } else {
            setItemIndex((itemIndex + items.length - 1) % items.length);
        }
    }

    const nextPhotoButtonTransition = (isEnter, isNext) => {
        if (isEnter) {
            if (isNext) {
                setNextColor("#91c8e6");
            } else {
                setBackColor("#91c8e6");
            }
        } else {
            if (isNext) {
                setNextColor("#e8bc82");
            } else {
                setBackColor("#e8bc82");
            }
        }
    }

    const [homeColor, setHomeColor] = useState("rgba(232, 188, 130, 1)");
    const homeStyles = {
        "-webkit-homeColor": "0.25s ease-in-out",
        'color': homeColor,
        'text-align': "left",
    }
    const homeButtonTransition = (isEnter) => {
        if (isEnter) {
            setHomeColor("#91c8e6");
        } else {
            setHomeColor("#e8bc82");
        }
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && window.innerWidth < window.innerHeight;

    if (!isMobile) {
        return (
                <div className="photopage-photo-display-container-desktop">
                    <PhotoDescription description={description} isGalleryMode={isGalleryMode} setIsGalleryMode={setIsGalleryMode}/>

                    {isGalleryMode 
                        ? 
                        <div className="photopage-photo-list-container-desktop">
                            <PiArrowLeftThin    
                                id='back'
                                className={"photopage-photo-next-image"}
                                onClick={() => {changePhoto(false)}} 
                                onMouseEnter={() => {nextPhotoButtonTransition(true, false)}}
                                onMouseLeave={() =>  {nextPhotoButtonTransition(false, false)}}
                                style={backStyles}
                                />
                            {items.map((element, index) => 
                                (
                                    <div id="div_for_visibility"
                                        style = {{
                                            display: index === itemIndex ? 'block' : 'none'
                                        }}
                                    >
                                        <PhotoFrame photo_item={element} is_mobile={isMobile} is_grid={false}/>
                                    </div>  
                                    
                                )
                            )}  
                            <PiArrowRightThin id='next'
                                className={"photopage-photo-next-image"}
                                onClick={() => {changePhoto(true)}} 
                                onMouseEnter={() => {nextPhotoButtonTransition(true, true)}}
                                onMouseLeave={() =>  {nextPhotoButtonTransition(false, true)}}
                                style={nextStyles} />
                        </div>
                        :
                        <div className="photopage-photo-list-container-desktop">
                            <PhotoGrid
                                photoItems={items}
                                setItemIndex={setItemIndex}
                                setIsGalleryMode={setIsGalleryMode}
                                isMobile={isMobile}
                            />
                        </div>
                    }
                </div>
            )
    } else {
        return ( 
            <div className="photopage-photo-display-container-phone">
                <div className="photopage-top-pane-fit">
                    <Link className="photopage-photo-go-back-phone" 
                        onMouseEnter={() => {homeButtonTransition(true)}}
                        onMouseLeave={() =>  {homeButtonTransition(false)}} 
                        style={homeStyles}
                        to="/">
                        <PiArrowUUpLeftThin/>
                    </Link>
                    {items.map((element, index) => 
                            (
                                <div id="div_for_visibility"
                                    style = {{display: index === itemIndex ? 'block' : 'none'}}
                                >
                                    <PhotoFrame photo_item={element} is_mobile={isMobile}/>
                                </div>  
                            )
                        )}
                </div>
                <div className="photopage-middle-pane-fit">

                    
                    <div className='photopage-photo-next-image-container-phone'>
                        <PiArrowLeftThin id='back'
                            className={"photopage-photo-next-image-phone"}
                            onClick={() => {changePhoto(false)}} 
                            onMouseEnter={() => {nextPhotoButtonTransition(true, false)}}
                            onMouseLeave={() =>  {nextPhotoButtonTransition(false, false)}}
                            style={backPhoneStyles} />
                        <PiArrowRightThin id='next'
                            className={"photopage-photo-next-image-phone"}
                            onClick={() => {changePhoto(true)}} 
                            onMouseEnter={() => {nextPhotoButtonTransition(true, true)}}
                            onMouseLeave={() =>  {nextPhotoButtonTransition(false, true)}}
                            style={nextPhoneStyles} />
                    </div>
                </div>
                <PhotoDescriptionPhone description={description} className='photopage-bottom-pane-fit'/>
            </div>
        )
    }
}