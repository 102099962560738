import React, { useState } from 'react';
// import '../../styles/collectionsPage.css';
// import '../../styles/homePage.css';
import '../../styles/photoFrame.css';

import {ORIENTATIONS, MAX_RESOLUTIONS} from '../../data/data.js'

export default function PhotoFrame({photo_item, is_mobile, is_grid}) {
    // PhotoItem {
    // IN LEGACY VERSIONS name === "photo_break" inteded to serve a line break
    //          url: string
    //          orientation: ORIENTATIONS
    //          name: string 
    //          }

    const PHOTO_LOAD_STATE = {
        NONE : 0, // Nothing has loaded
        HIGH_RES: 1, // high res image has loaded 
    }

    const [photoLoadState, setPhotoLoadState] = useState(PHOTO_LOAD_STATE.NONE)
    const [animationDelayPassed, setanimationDelayPassed] = useState(false)
    const [isHover, setIsHover] = useState(false);

    const createAnimationDelayedTimer = () => {
        setTimeout(() => {
            setanimationDelayPassed(true);
          }, is_grid ? 0 : 500);
    }

    const getContainerClass = () => {
        if (is_grid) {
            return "photoframe-container-grid" + (photo_item.orientation === ORIENTATIONS.PORTRAIT ? "-tall": "-wide");
        } else if (photo_item.orientation === ORIENTATIONS.PORTRAIT) {
            return "photoframe-container-" + (is_mobile ? "mobile" : "desktop") + "-tall";
        } else {
            return "photoframe-container-" + (is_mobile ? "mobile" : "desktop") + "-wide";
        }
    }

    const getLoadingPlaceholderClass = () => {
        if (is_grid) {
            return "loading-placeholder-grid";
        } else if (photo_item.orientation === ORIENTATIONS.PORTRAIT) {
            return "loading-placeholder-" + (is_mobile ? "mobile" : "desktop") + "-tall";
        } else {
            return "loading-placeholder-" + (is_mobile ? "mobile" : "desktop") + "-wide";
        }
    }

    return (
            <div>
                        {
                            <div onLoad={createAnimationDelayedTimer}>
                                {/* Loading div */}
                                <div style={
                                    {
                                        display: (photoLoadState === PHOTO_LOAD_STATE.NONE || !animationDelayPassed)?"block":"none",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    className={getLoadingPlaceholderClass()}
                                    />
                                {/* High res div */}
                                <div>
                                    <img 
                                        src={photo_item.url +  (photo_item.orientation === ORIENTATIONS.PORTRAIT ? "=h" + MAX_RESOLUTIONS.PORTRAIT.height : "=w" + MAX_RESOLUTIONS.LANDSCAPE.width)}        
                                        alt={photo_item.name}
                                        width={photo_item.orientation === ORIENTATIONS.PORTRAIT ? MAX_RESOLUTIONS.PORTRAIT.width : + MAX_RESOLUTIONS.LANDSCAPE.width}
                                        height={photo_item.orientation === ORIENTATIONS.PORTRAIT ? MAX_RESOLUTIONS.PORTRAIT.height : + MAX_RESOLUTIONS.LANDSCAPE.height}
                                        onLoad={
                                            () => {
                                                    setPhotoLoadState(PHOTO_LOAD_STATE.HIGH_RES);
                                                    console.log("loaded high res");
                                                    }}
                                                    onMouseEnter={ () => setIsHover(true)}
                                                    onMouseLeave={  () =>  setIsHover(false)}

                                        style={
                                            {
                                                display: (animationDelayPassed && photoLoadState === PHOTO_LOAD_STATE.HIGH_RES)?"block":"none",
                                                animation: "fadeIn 0.45s",
                                            }}
                                            className={getContainerClass() + (isHover && is_grid ? " shaded" : "" )}
                                        referrerPolicy="no-referrer"
                                    >
                                    </img>
                                </div>
                            </div>
                        }
                </div>
            // </div>
    );
}