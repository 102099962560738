import React from 'react';
import '../../styles/collectionsPage.css';
import '../../styles/photoPage.css';


function PhotoDescriptionPhone({description}) {
    return (
        <div className="photopage-photo-description-container-phone">
                <h2 className="photopage-photo-description-text-phone"  style={{ color: '#4f3a26' }}>
                    {description}
                    <p className="photopage-photo-contact-text-phone"  style={{ color: '#1f211f' }}>For full quality images contact joshuabaum12<b>at</b>gmail<b>dot</b>com</p>    
                </h2>
                
        </div>
    );
}

export default PhotoDescriptionPhone;