import React from 'react';
import '../../styles/collectionsPage.css';


function CollectionsTitleBar({title}) {
    return (
        <div className="collections-title-header">
            <h1 className="collections-title">
                <h2 className="collections-title-letter"  style={{ color: '#4f3a26' }}>{title}</h2>
            </h1>
        </div>
    );
}

export default CollectionsTitleBar;