import React, { useState } from 'react';
import PhotoFrame from './PhotoFrame';

import '../../styles/photoGrid.css';


export default function PhotoGrid({photoItems, setItemIndex, setIsGalleryMode, isMobile}) {
   
    return (
        <div className='grid-container-desktop'>
                {photoItems.map((element, index) => 
                                (
                                    <div className="grid-item-desktop"
                                        onClick={() => {
                                            setItemIndex(index);
                                            setIsGalleryMode(true);
                                        }}
                                    >
                                        <PhotoFrame 
                                            photo_item={element} 
                                            is_mobile={isMobile} 
                                            is_grid={true}
                                        />
                                    </div>
                                )
                            )}  


        </div>
    );
}