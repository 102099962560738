import React, { useState } from 'react';

import '../../styles/collectionsPage.css';
import '../../styles/photoPage.css';

import { PiArrowUUpLeftThin } from "react-icons/pi";

import {Link} from 'react-router-dom';


function PhotoDescription({description, isGalleryMode, setIsGalleryMode}) {
    const [nextColor, setNextColor] = useState("rgba(232, 188, 130, 1)");
    const [isModeClicked, setIsModeClicked] = useState(false);

    const homeStyles = {
        "-webkit-transition": "0.25s ease-in-out",
        'color': nextColor,
        'text-align': "left",
    }


    const homeButtonTransition = (isEnter) => {
        if (isEnter) {
            setNextColor("#91c8e6");
        } else {
            setNextColor("#e8bc82");
        }
    }
    return (
        <div className="photopage-photo-description-container">
                <Link className="photopage-photo-go-back" to="/"
                onMouseEnter={() => {homeButtonTransition(true)}}
                onMouseLeave={() =>  {homeButtonTransition(false)}}>
                    <PiArrowUUpLeftThin style={homeStyles}
                    />
                </Link>
                <h2 className="photopage-photo-description-text"  style={{ color: '#4f3a26' }}>
                    {description}
                    <p className="photopage-photo-contact-text"  
                        style={{ color: '#1f211f' }}
                        onClick={() => window.location = 'mailto:joshuabaum12@gmail.com'}
                        >For full quality images contact joshuabaum12<b>at</b>gmail<b>dot</b>com</p>    
                    <div 
                        style={{ color: '#1f211f', marginTop: '30vh'}}
                        onClick={() => {
                            setIsModeClicked(true);
                            setIsGalleryMode(!isGalleryMode);}}
                        onAnimationEnd={() => setIsModeClicked(false)}
                        className= {isModeClicked ? "photopage-photo-mode-gallery-text" : null }>
                        {isGalleryMode
                            ? 
                            <p className="photopage-photo-contact-text"><b>Gallery </b> / Index</p>
                            :
                            <p className="photopage-photo-contact-text">Gallery / <b>Index</b></p>
                        }
                    </div>
                </h2>

                
        </div>
    );
}

export default PhotoDescription;